import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} variant="light">
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100 customCarousel"
          src="./images/carousel/1.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100 customCarousel"
          src="./images/carousel/2.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100 customCarousel"
          src="./images/carousel/3.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}
// render(<ControlledCarousel />);
