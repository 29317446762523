
// import CSS
import '../css/styles.css';
import '../css/responsive.css';
import '../css/fonts.css';
import '../css/animations.css';
import 'bootstrap/dist/css/bootstrap.css';

// import components
import Navbar from "../components/Navbar.js";
import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer.js";
import Projet from "../components/Projet.js";
import Form from "../components/Form.js";

function Contact() {
  return (
    <>
      <Navbar></Navbar>
      <p className="pageTitle">
        Rédigez votre message
      </p>
      <Form></Form>
      <Projet></Projet>
      <Footer></Footer>
      <Outlet />
    </>
  )
}

export default Contact