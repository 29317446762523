import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Statistique() {
	return (
		<div className="stat">
			<Container>
				<Row>
					<Col xl>
						<div className="statTitre">
							Réalisez vos projets !
						</div>
					</Col>
				</Row>
				<Row>
					<Col xl>
						<div className='statTexte'>
							<p className="number">133765</p>
							<p className="numberText">m² de surface couverte par hydroseeding</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xl>
						<div className='statTexte'>
							<p className="number">17306</p>
							<p className="numberText">m² de couverture bionatte</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xl>
						<div className='statTexte'>
							<p className="number">3084</p>
							<p className="numberText">m3 de Gabions installé</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xl>
						<div className='statTexte'>
							<p className="number">11</p>
							<p className="numberText">ans d'expérience</p>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Statistique