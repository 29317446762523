
// import CSS
import '../css/styles.css';
import '../css/responsive.css';
import '../css/fonts.css';
import '../css/animations.css';
import 'bootstrap/dist/css/bootstrap.css';

// import components
import Navbar from "../components/Navbar.js";
import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer.js";
import Projet from "../components/Projet.js";
import Gallery from "../components/Gallery.js";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Realisations() {
  return (
    <>
      <Navbar></Navbar>
      <Container className="gallery">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Gallery ></Gallery>
          </Col>
        </Row>
      </Container>
      <Projet></Projet>
      <Footer></Footer>
      <Outlet />
    </>
  )
}

export default Realisations