
// import CSS
import '../css/styles.css';
import '../css/responsive.css';
import '../css/fonts.css';
import '../css/animations.css';
import 'bootstrap/dist/css/bootstrap.css';

// import components
import Navbar from "../components/Navbar.js";
import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer.js";
import Projet from "../components/Projet.js";

function MurGabions() {
  return (
    <>
      <Navbar></Navbar>
      <h1 className="pageTitle">
        Murs en gabions
      </h1>
      <p className="pageText">
        Nous réalisons des murs de soutènement en gabions. Ce mur « poids » permet généralement de gagner de la surface utilisable autour de votre maison d’habitation. Ces travaux réalisés dans les règles de l’art sont déductibles des impôts comme une amélioration durable du patrimoine immobilier.
      </p>
      <p className="pageText">
        Pour ce projet il convient tout d’abord de dimensionner le mur, en général il faut prévoir une base égale au 1/3 de la hauteur par exemple 50 cm de large pour 1.50 m de haut et 1 m de large pour 3 m de haut. Ensuite il faut définir l’accès pour le chantier : passage des réseaux d’eaux et d’électricités, la qualité du sol pour l’assise et bien sûr les limites du terrain.
      </p>
      <p className="pageText">
        Nous effectuons le remplissage des pierres dans les cages sur place, mais il est aussi possible de transporter les gabions préremplis et de les ajuster comme des briques. Les gabions sont attachés les uns aux autres.
      </p>
      <p className="pageText">
        Il faut prévoir ensuite de remplir de remblai derrière le mur puis de terre végétale ou gravier en surface.
      </p>
      <p className="pageText">
        On peut facilement poser une clôture sur les gabions de la dernière rangée.
      </p>
      <div className="pageImg murGabions"></div>
      <Projet></Projet>
      <Footer></Footer>
      <Outlet />
    </>
  )
}

export default MurGabions