import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';



function TextControlsExample() {
  const [formData, setFormData] = useState({
    prenom: '',
    nom: '',
    adresse1: '',
    adresse2: '',
    quartier: '',
    ville: '',
    cp: '',
    mail: '',
    tel: '',
    sujet: '',
    message: ''
  });

  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  const [loading, setLoading] = useState(false);

  const handleSubmit = event => {
    setLoading(true);
    event.preventDefault();
    axios.post('http://45.32.191.159:3000/hydroseed-react/src/api/form.php', formData, {
      crossdomain: true
    }).then((response) => {
      console.log(response);
      document.getElementById("mailSend").setAttribute("style", "display:block;");
      document.getElementById("hideForm").setAttribute("style", "display:none;");
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <>
      <Container>
        <div className="alert alert-success mailSend newsletterInput" role="alert" id="mailSend">
          Le mail a bien été envoyé, vous recevez une copie à l'adresse
        </div>
        <Row className="justify-content-md-center">
          <Col lg="5">
            <Form onSubmit={handleSubmit} className="hideForm" id="hideForm">
              <Row>
                <Form.Group className="mb-3" >
                  <Row>
                    <Col>
                      <Form.Label>Prénom *</Form.Label>
                      <Form.Control name="prenom" onChange={handleChange} value={formData.prenom} placeholder="Martin" required="required" />
                    </Col>
                    <Col>
                      <Form.Label>Nom *</Form.Label>
                      <Form.Control name="nom" onChange={handleChange} value={formData.nom} placeholder="DUPOND" required="required" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Adresse</Form.Label>
                  <Form.Control name="adresse1" type="text" onChange={handleChange} value={formData.adresse1} placeholder="2, rue des Cocotiers" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Complément</Form.Label>
                  <Form.Control name="adresse2" type="text" onChange={handleChange} value={formData.adresse2} placeholder="Appartement n°12" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label>Quartier</Form.Label>
                      <Form.Control name="quartier" onChange={handleChange} value={formData.quartier} placeholder="Ducos" />
                    </Col>
                    <Col>
                      <Form.Label>Ville</Form.Label>
                      <Form.Control name="ville" onChange={handleChange} value={formData.ville} placeholder="Nouméa" />
                    </Col>
                    <Col>
                      <Form.Label>Code Postal</Form.Label>
                      <Form.Control name="cp" onChange={handleChange} value={formData.cp} placeholder="98863" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Col lg="8">
                      <Form.Label>Adresse e-mail *</Form.Label>
                      <Form.Control name="mail" type="email" onChange={handleChange} value={formData.email} placeholder="votremail@example.com" required="required" />
                    </Col>
                    <Col lg="4">
                      <Form.Label>Téléphone *</Form.Label>
                      <Form.Control name="tel" type="tel" onChange={handleChange} value={formData.tel} placeholder="12 34 56" required="required" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Objet :">
                    <Form.Select name="sujet" value={formData.sujet} onChange={handleChange}>
                      <option>Selectionner votre sujet</option>
                      <option value="Demande de devis">Demande de devis</option>
                      <option value="Prise de rendez-vous pour une analyse">Prise de rendez-vous pour une analyse</option>
                      <option value="Question technique">Question technique</option>
                      <option value="Autres">Autres</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Saisissez votre message ... *</Form.Label>
                  <Form.Control name="message" as="textarea" onChange={handleChange} value={formData.message} rows={3} required="required" />
                </Form.Group>
              </Row>
              {loading ? (
                <div className="rotateLoad">
                  <Icon.ArrowClockwise></Icon.ArrowClockwise>
                </div>
              ) : (
                <Button id="submitBtn" type="submit" className="contactBtn" onClick={handleSubmit}>
                  Envoyer
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TextControlsExample;