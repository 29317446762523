import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import TopScroll from './TopScroll';


function Footer() {
  return (
    <>
      <footer className="footer">
        <Container bg="dark" variant="dark">
          <Row>
            <Col lg className="meta">
              <Link to="/stabilisation-de-talus" className="metaLink" onClick={TopScroll()}>Stabilisation de talus</Link>
              <Link to="/mur-en-gabions" className="metaLink" onClick={TopScroll()}>Mur en gabions</Link>
              <Link to="/confortement-des-berges" className="metaLink onClick={TopScroll()}">Confortement des berges</Link>
              <Link to="/travaux-divers" className="metaLink" onClick={TopScroll()}>Travaux divers</Link>
              <Link to="/realisations" className="metaLink" onClick={TopScroll()}>Réalisations</Link>
            </Col>
            <Col lg className="meta">
              <Nav.Link href="https://www.province-nord.nc/" className="metaLink" target="_blank">Province Nord</Nav.Link>
              <Nav.Link href="https://www.province-sud.nc/" className="metaLink" target="_blank">Province Sud</Nav.Link>
              <Nav.Link href="https://www.1012.nc/professionnels/mobilis/details/511c40dd711e1ee7972939bc767e1f28d8e0d70e/hydroseed_sarl" className="metaLink" target="_blank" rel="noreferrer">1012.nc</Nav.Link>
              <Nav.Link href="https://gouv.nc/" className="metaLink" target="_blank">Nouvelle-Calédonie</Nav.Link>
            </Col>
            <Col lg className="meta">
              <Link to="/" className="metaLink" onClick={TopScroll()}>Accueil</Link>
              <Link to="/mentions-legales" className="metaLink" onClick={TopScroll()}>Mentions légales</Link>
              <Link to="/a-propos" className="metaLink" onClick={TopScroll()}>A propos</Link>
              <Link to="/contact" className="metaLink" onClick={TopScroll()}>Contact</Link>
              <Nav.Link href="https://www.facebook.com/profile.php?id=100057332955158" className="metaLink" target="_blank" rel="noreferrer">Facebook</Nav.Link>
            </Col>
          </Row>
        </Container>
        <div className='endpage'>Hydroseed 2022 - Olivier GOUJON - 75 98 51</div>
      </footer>
    </>
  )
}

export default Footer