
import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Icon from 'react-bootstrap-icons';
import TopScroll from './TopScroll';
import { Link } from "react-router-dom";

function Projet() {
  return (
    <>
      <div className="projet">
        <Container>
          <Row>
            <Col lg>
              <div className="questionTitre">
                Vous avez une question ?
              </div>
            </Col>
          </Row>
          <Row className="justify-content-lg-center">
            <Col lg="auto">
              <Link to="/contact" onClick={TopScroll()} >
                <div className="question">
                  Contactez-nous
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container className="margeTop">
          <Row>
            <Col lg>
              <a href="https://m.me/100057332955158" className='contactTag' target="_blank" rel="noreferrer">
                <Icon.Messenger className="contactIco"></Icon.Messenger>
                <div className="contactText">Hydroseed & Gabions NC</div>
              </a>
            </Col>
            <Col lg>
              <a href="tel:00687759851" className='contactTag'>
                <Icon.Telephone className="contactIco"></Icon.Telephone>
                <div className="contactText">75.98.51</div>
              </a>
            </Col>
            <Col lg>
              <a href="mailto:hydroseed.nc@hotmail.fr" className='contactTag'>
                <Icon.Envelope className="contactIco"></Icon.Envelope>
                <div className="contactText">hydroseed.nc@hotmail.fr</div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Projet