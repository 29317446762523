
// import CSS
import '../css/styles.css';
import '../css/responsive.css';
import '../css/fonts.css';
import '../css/animations.css';
import 'bootstrap/dist/css/bootstrap.css';
import * as Icon from 'react-bootstrap-icons';


// import components
import Navbar from "../components/Navbar.js";
import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer.js";
import Projet from "../components/Projet.js";

function Contact() {
  return (
    <>
      <Navbar></Navbar>
      <p className="pageTitle">
        <Icon.FileEarmarkX size="40" />  Erreur 404 : page introuvable
      </p>
      <Projet></Projet>
      <Footer></Footer>
      <Outlet />
    </>
  )
}

export default Contact