import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './components/reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

// PAGES
import Stabilisation from "./pages/Stabilisation";
import MurGabions from "./pages/MurGabions";
import ConfortementBerges from "./pages/ConfortementBerges";
import TravauxDivers from "./pages/TravauxDivers";
import Realisations from "./pages/Realisations";
import Aboutus from "./pages/Aboutus";
import Contact from "./pages/Contact";
import MentionsLegales from "./pages/MentionsLegales";
import Error404 from "./pages/Error404";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/stabilisation-de-talus" element={<Stabilisation />} />
        <Route exact path="/mur-en-gabions" element={<MurGabions />} />
        <Route exact path="/confortement-des-berges" element={<ConfortementBerges />} />
        <Route exact path="/travaux-divers" element={<TravauxDivers />} />
        <Route exact path="/realisations" element={<Realisations />} />
        <Route exact path="/a-propos" element={<Aboutus />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
