// import CSS
import '../css/styles.css';
import '../css/responsive.css';
import '../css/fonts.css';
import '../css/animations.css';
import 'bootstrap/dist/css/bootstrap.css';

// import components
import Navbar from "../components/Navbar.js";
import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer.js";
import Projet from "../components/Projet.js";

function Stabilisation() {
  return (
    <>
      <Navbar></Navbar>
      <h1 className="pageTitle">
        Stabilisation de la surface des pentes
      </h1>
      <p className="pageText">
        La société HYDROSEED est spécialisée pour le contrôle de l’érosion sur les talus.
      </p>
      <p className="pageText">
        L’objectif est de stabiliser la surface du sol de la pente avec un complexe bionatte + couverture végétale.
      </p>
      <p className="pageText">
        L’application des bionattes (tapis biodégradables ou permanents) stabilise immédiatement la surface du sol en retenant la terre fine et les petits cailloux. Ces bionattes permettent la végétalisation de la pente avec des espèces végétales pionnières.
      </p>
      <p className="pageText">
        Quand les conditions de surface de sol le permettent nous effectuons un hydroseeding pour établir une couverture végétale de graminées et une membrane anti-érosion. Nous utilisons également des plantes rampantes autochtones ou endémiques qui colonisent le talus sur la bionatte.
      </p>
      <p className="pageText">
        Pour les talus à risque, nous appliquons une toile-béton (Concrete Canvas) qui permet un cunitage rapide et efficace. Exemple de travaux avec ces toile-béton : gestion des eaux de ruissellement, stabilisation de talus en dévers.
      </p>
      <div className="pageImg stabilisation"></div>
      <Projet></Projet>
      <Footer></Footer>
      <Outlet />
    </>
  )
}

export default Stabilisation 