
// import CSS
import '../css/styles.css';
import '../css/responsive.css';
import '../css/fonts.css';
import '../css/animations.css';
import 'bootstrap/dist/css/bootstrap.css';

// import components
import Navbar from "../components/Navbar.js";
import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer.js";
import Projet from "../components/Projet.js";

function Aboutus() {
  return (
    <>
      <Navbar></Navbar>
      <h1 className="pageTitle">
        A propos de nous
      </h1>
      <p className="pageText">
        La société HYDROSEED s’intéresse au génie végétal dans le secteur du génie civil.
      </p>
      <p className="pageText">
        Nous étudions toutes les solutions techniques pour la protection de l’environnement, notamment pour le confortement des talus et la stabilisation de la surface des pentes.
      </p>
      <div className="pageLogo hydroseedLogo"></div>
      <h2 className="pageSubTitle">
        Qu’est ce que l’hydroseeding ?
      </h2>
      <p className="pageText">
        L’hydroseeding (ensemencement hydraulique en français) est une méthode de semis consistant à mélanger à de l’eau, des semences, de l'engrais et des stabilisants pour former une pulpe. Ce mélange est pulvérisé sur les talus pour élaborer une couverture végétale. La surface enherbée contrôle l'érosion de la terre fine.
      </p>
      <div className="pageLogo gabionsLogo"></div>
      <h2 className="pageSubTitle">
        Gabions
      </h2>
      <p className="pageText">
        Désigne une sorte de casier, le plus souvent fait de solides fils de fer tressés, contenant des pierres. Il est utilisé en génie civil dans la construction d'un mur de soutènement ou d'une berge artificielle non étanche pour lutter notamment contre l'érosion fluviale ou torrentielle.
      </p>
      <p className="pageText">
        L'avantage de cette solution repose dans son coût modique si l'on dispose de pierres solides sur place, sa bonne tenue, sa facilité de mise en œuvre et sa modularité.
      </p>
      <Projet></Projet>
      <Footer></Footer>
      <Outlet />
    </>
  )
}

export default Aboutus