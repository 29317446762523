
// import CSS
import '../css/styles.css';
import '../css/responsive.css';
import '../css/fonts.css';
import '../css/animations.css';
import 'bootstrap/dist/css/bootstrap.css';

// import components
import Navbar from "../components/Navbar.js";
import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer.js";
import Projet from "../components/Projet.js";
import { ChevronRight } from 'react-bootstrap-icons';

function TravauxDivers() {
  return (
    <>
      <Navbar></Navbar>
      <h1 className="pageTitle">
        Travaux divers
      </h1>
      <p className="pageText">
        Nous réalisons des travaux divers d’aménagement de jardins ou dans les espaces naturels.
      </p>
      <p className="pageText">
        Par exemple :<br />
        <ChevronRight></ChevronRight>Etudes environnementales préalables aux travaux dans des espaces naturels<br />
        <ChevronRight></ChevronRight>Travaux d’aménagement de retenues de terres<br />
        <ChevronRight></ChevronRight>Travaux d’étanchéité pour les retenues d’eaux<br />
        <ChevronRight></ChevronRight>Travaux divers avec des résines epoxy ou polyester sur tissus de verre<br />
      </p>
      <p className="pageText">

        Prenez contact avec la sarl Hydroseed pour plus de renseignements pour votre projet
      </p>
      <div className="pageImg travauxDivers"></div>
      <Projet></Projet>
      <Footer></Footer>
      <Outlet />
    </>
  )
}

export default TravauxDivers