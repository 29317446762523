import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import * as Icon from 'react-bootstrap-icons';
import { Link } from "react-router-dom";

function CollapsibleExample() {
  return (
    <Navbar collapseOnSelect expand="xl" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <div className='homeLogo'></div>
          </Link>
        </Navbar.Brand>
        <Navbar.Brand>
          <Link to="/">
          <h1 className="homeTitle">Hydroseed</h1>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto navRight">
            <Link to="/stabilisation-de-talus" className="navLink">Stabilisation de talus</Link>
            <Link to="/mur-en-gabions" className="navLink">Mur en gabions</Link>
            <Link to="/confortement-des-berges" className="navLink">Confortement des berges</Link>
            <Link to="/travaux-divers" className="navLink">Travaux divers</Link>
            <Link to="/realisations" className="navLink" >Réalisations</Link>
          </Nav>
          <Nav>
            <Nav.Link href="https://www.facebook.com/profile.php?id=100057332955158" className="navSocial" target="_blank"><Icon.Facebook /></Nav.Link>
            <Nav.Link href="https://www.facebook.com/profile.php?id=100057332955158" className="navLink navFacebook" target="_blank">Facebook</Nav.Link>
          </Nav>
          <Nav>
            <Link to="/a-propos" className="navLink">A propos</Link>
            <Link to="/contact" className="navLink">Contact </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;