// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/klik.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/lobster.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* -- FONT -- */\n\n\n@font-face {\n  font-family: klik;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('otf');\n}\n\n@font-face {\n  font-family: lobster;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('otf');\n}", "",{"version":3,"sources":["webpack://./src/css/fonts.css"],"names":[],"mappings":"AAAA,eAAe;;;AAGf;EACE,iBAAiB;EACjB,sHAAkF;AACpF;;AAEA;EACE,oBAAoB;EACpB,sHAAwF;AAC1F","sourcesContent":["/* -- FONT -- */\n\n\n@font-face {\n  font-family: klik;\n  src: url('fonts/klik.otf') format('truetype'), url('fonts/klik.otf') format('otf');\n}\n\n@font-face {\n  font-family: lobster;\n  src: url('fonts/lobster.otf') format('truetype'), url('fonts/lobster.otf') format('otf');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
