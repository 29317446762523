// import CSS
import './css/styles.css';
import './css/responsive.css';
import './css/fonts.css';
import './css/animations.css';
import 'bootstrap/dist/css/bootstrap.css';

// import Components
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Carousel from "./components/Carousel.js";
import Footer from "./components/Footer.js";
import Statistique from "./components/Statistique.js";
import Projet from "./components/Projet.js";


export default function App() {
  return (
    <div>
      <Navbar></Navbar>
      <Carousel></Carousel>
      <Statistique></Statistique>
      <Projet></Projet>
      <Footer></Footer>
      <Outlet />
    </div>
  );
}