import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Masonry from '@mui/lab/Masonry';
import Box from '@mui/material/Box';
import * as Icon from 'react-bootstrap-icons';


const ImageMasonry = () => {
  const [photos, setPhotos] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [loader, setLoader] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setShowDiv(true);
      setLoader(false)
    }, 3000);

    axios
      .get('http://45.32.191.159:3000/hydroseed-react/src/api/gallery.php', {
        crossdomain: true
      }).then(res => {
        setPhotos(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (

    <Box className="galleryBox" sx={{}}>
      {loader && <div className="imgLoader"><Icon.ArrowClockwise></Icon.ArrowClockwise></div>}
      {showDiv && (
         <Masonry columns={2} spacing={1}>
         {photos.map((photo, index) => (
           <div key={index}>
             <img
               src={photo.img}
               alt={photo.title}
               loading="lazy"
               className="galleryImg"
             />
           </div>
         ))}
       </Masonry>
      )}
    </Box>
  );
};

export default ImageMasonry;